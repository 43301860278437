import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser } from '../slices/userSlice';
import { selectBasket } from '../slices/basketSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

// Gestion de la navigation
const Header = (props) => {
    const user = useSelector(selectUser);
    const basket = useSelector(selectBasket);

    return (
        <div className="header-nav">
            <nav>
                {/* Envelopper le contenu conditionnel dans un fragment */}
                <>
                    {user.isLogged ? (
                        <>
                            <div className="headerBloc1">
                                <Link to="/">Accueil</Link>
                                <Link to="/basket">
                                    Panier {basket.basket.length > 0 && <span className="span-basket">{basket.basket.length}</span>}
                                </Link>
                            </div>
                            <div className="headerBloc2">
                                <Link to="/profil">Profil</Link>
                                <Link to="/logout">
                                    <FontAwesomeIcon icon={faSignOutAlt} />
                                </Link>
                            </div>
                        </>
                    ) : (
                        <div className="headerBloc2">
                            <Link to="/register">S'enregistrer</Link>
                            <Link to="/login">Se connecter</Link>
                        </div>
                    )}
                </>
            </nav>
        </div>
    );
};

export default Header;
