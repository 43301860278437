import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectBasket, modifyBasket } from '../slices/basketSlice';
import { getOneCoach, getAllLessonsByCoach } from '../api/lesson';
import {
  Image,
  Video,
  Transformation,
  CloudinaryContext
} from "cloudinary-react";
import moment from 'moment';
import localization from 'moment/locale/fr';

moment.updateLocale('fr', localization);

const Detail = (props) => {
  const basket = useSelector(selectBasket);
  const dispatch = useDispatch();

  const id = props.params.id;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [sport, setSport] = useState("");
  const [description, setDescription] = useState("");
  const [imgUrl, setImgUrl] = useState('');
  const [lessons, setLessons] = useState([]);
  const [msg, setMsg] = useState(null);

  useEffect(() => {
    getOneCoach(id)
      .then((res) => {
        console.log("coach", res);
        setFirstName(res.result.firstName);
        setLastName(res.result.lastName);
        setSport(res.result.sport);
        setDescription(res.result.description);
        setImgUrl(res.result.imageUrl);

        getAllLessonsByCoach(res.result.id)
          .then((response) => {
            console.log("lessons", response.result);
            let result = response.result;
            for (let i = 0; i < result.length; i++) {
              let myPrice = moment(result[i].end) - moment(result[i].start);
              myPrice = (myPrice / 3600000) * parseFloat(result[i].tjm);
              result[i].price = Math.round(myPrice * 100) / 100; // Arrondi avec deux décimales
            }
            setLessons(response.result);
          })
          .catch(err => console.log(err));
      })
      .catch(err => console.log(err));
  }, [id]);

  const addToBasket = (basket, newLesson) => {
    let same = basket.findIndex((b) => b.id === newLesson.id);

    if (same === -1) {
      let newBasket = [...basket, newLesson];
      let lsBasket = JSON.stringify(newBasket);
      window.localStorage.setItem('coachme-basket', lsBasket);
      dispatch(modifyBasket(newBasket));
    } else {
      return "Attention, vous avez déjà ajouté ce cours dans votre panier";
    }
  };

  const removeToBasket = (basket, myLesson) => {
    let newBasket = basket.filter(b => b.id !== myLesson.id);
    let lsBasket = JSON.stringify(newBasket);
    window.localStorage.setItem('coachme-basket', lsBasket);
    dispatch(modifyBasket(newBasket));
  };

  return (
    <div>
      <h1>{firstName} {lastName}</h1>

      <CloudinaryContext cloudName="dt6k2cynj">
        <div className='container_img'>
          <Image publicId={imgUrl} className="profilImg">
            <Transformation quality="auto" fetchFormat="auto" />
          </Image>
        </div>
      </CloudinaryContext>

      <p>Sport : {sport} </p>
      <p>Description : {description} </p>
      {msg !== null && <p>{msg}</p>}

      {lessons.length > 0 && <ul className="calendar-user">
        {
          lessons.map((lesson) => {
            let isInBasket = false;

            if (basket.basket.findIndex(basket => lesson.id === basket.id) !== -1) {
              isInBasket = true;
            }
            if (lesson.status === "free" && moment(lesson.start) > moment()) {
              return (<li key={lesson.id}>
                <h3>Cours de {lesson.sport}</h3>
                <p>{moment(lesson.start).format('L')}</p>
                <p>début : {moment(lesson.start).format('LT')} / fin : {moment(lesson.end).format('LT')}</p>
                <p> Prix de cette scéance : {lesson.price} €</p>

                {!isInBasket && <button
                  onClick={(e) => {
                    let error = addToBasket(basket.basket, lesson);
                    isInBasket = true;
                    if (error) {
                      setMsg(error);
                    }
                  }}
                >Réserver ce cours</button>}

                {isInBasket && <button
                  className="red-button"
                  onClick={(e) => {
                    console.log('click');
                    removeToBasket(basket.basket, lesson);
                  }}
                >Retirer ce cours</button>}
              </li>);
            }
          })
        }
      </ul>}
    </div>
  );
}

export default Detail;
