import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import {loadStripe} from '@stripe/stripe-js';
import {useSelector, useDispatch} from 'react-redux'
import {selectUser} from '../slices/userSlice'
import {selectBasket, modifyBasket, cleanBasket} from '../slices/basketSlice';
import {Navigate} from 'react-router-dom';
import {checkPayment, validatePayment} from '../api/lesson'
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js'

const CheckoutForm = (props) =>{
    
    
    const [error, setError] = useState(false)
    const [redirectSuccess, setRedirectSuccess] = useState(false)


    const basket = useSelector(selectBasket)
    const user = useSelector(selectUser)
    const dispatch = useDispatch()
    
    const stripe = useStripe();
    const elements = useElements();

    const removeToBasket = (basket, myLesson) =>{
        let newBasket = basket.filter(b => b.id !== myLesson.id);
        
        let lsBasket = JSON.stringify(newBasket);
        window.localStorage.setItem('coachme-basket', lsBasket);
        
        dispatch(modifyBasket(newBasket))
    }
    
    //fonction de paiement lors de la validation de la CB
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!stripe || !elements) {
            setError("Problème avec le terminal de paiement");
            return;
        }
    
        let data = {
            email: user.infos.email,
            basket: basket.basket,  // Corriger cette ligne
            user_id: user.infos.id  // Assurez-vous d'envoyer l'user_id ici
        };
    
        console.log("User data:", user);  // Vérifiez le contenu de user
        console.log("Data before payment:", data);  // Vérifiez les données avant de les envoyer
    
        try {
            const paymentAuth = await checkPayment(data);
            if (paymentAuth.status === 500) {
                setError("Echec du check de paiement");
                return;
            }
    
            const secret = paymentAuth.client_secret;
            const payment = await stripe.confirmCardPayment(secret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                    billing_details: {
                        email: user.infos.email
                    }
                }
            });
    
            if (payment.error) {
                console.error("Payment error:", payment.error);
                setError("Echec de la tentative de paiement de votre CB");
            } else if (payment.paymentIntent.status === "succeeded") {
                console.log("Money is in the bank");
    
                let validateData = {
                    user_id: user.infos.id,  // Utilisez user.infos.id ici
                    basket: basket.basket  // Corriger cette ligne
                };
    
                console.log("Validate data:", validateData);  // Vérifiez les données de validation
    
                let validateResponse = await validatePayment(validateData);
                if (validateResponse.status === 200) {
                    setRedirectSuccess(true);
                } else {
                    console.error("Validation error:", validateResponse);
                    setError("Erreur lors de la validation du paiement");
                }
            }
        } catch (err) {
            console.error("Payment attempt error:", err);
            setError("Erreur lors de la tentative de paiement");
        }
    };
    

    if(redirectSuccess){
        return <Navigate to="/success" />
    }
    return (
        <section>
            {error !== null && <p>{error}</p>}
            <form onSubmit={handleSubmit}>
                <CardElement
                    options={{
                        style: {
                        base: {
                          color: "#32325d",
                          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                          fontSmoothing: "antialiased",
                          fontSize: "16px",
                          "::placeholder": {
                            color: "#aab7c4",
                          },
                        },
                        invalid: {
                          color: "#fa755a",
                          iconColor: "#fa755a",
                        },
                      },
                    }}
                        
                />
                <button
                    disabled={props.stripe}
                >Payer</button>
            </form>
        </section>
    ) 
}

export default CheckoutForm